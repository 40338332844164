import Vue from 'vue'
import Vuex from 'vuex'

import { locale, LocaleStore } from './modules/locale'

Vue.use(Vuex)

const state = {}

const mutations = {}

export const actions = {}

export const modules = {
  locale,
}

const store = new Vuex.Store({
  state,
  actions,
  mutations,
  modules,
})

export default store

export const vxmModules = {
  locale: LocaleStore,
}

export const vxm = {
  locale: LocaleStore.CreateProxy(store, LocaleStore),
}
