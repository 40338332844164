import { Module, mutation, VuexModule } from 'vuex-class-component'
import Locale from '@/models/Locale'
import i18n from '@/i18n'

@Module({ namespacedPath: 'locale/' })
export class LocaleStore extends VuexModule {
  public locale: Locale = null

  @mutation
  public setLocale(locale: Locale): void {
    this.locale = locale
    const language = locale.language.split('_')[0]
    i18n.setLanguage(language)
  }
}

export const locale = LocaleStore.ExtractVuexModule(LocaleStore)
