import SelectedService from '../models/SelectedService'
import Portal from '../models/Portal'
import Place from '../models/Place'
import Service from '../models/Service'
import Resource from '../models/Resource'
import Slot from '../models/Slot'
import Locale from '../models/Locale'
import { AxiosError, AxiosInstance } from 'axios'
import { DateTime } from 'luxon'

export default class PortalData {
  public portal: Portal = new Portal()
  public places: Array<Place> = []
  public services: Array<Service> = []
  public slots: Array<Slot> = []
  public resources: Array<Resource> = []
  public locale: Locale
  public firstCanCancelTime: DateTime
  public firstCanModifyTime: DateTime

  private axios: AxiosInstance

  public constructor(axios: AxiosInstance) {
    this.axios = axios
  }

  public loadPortalId(portalId: string, callback: (err: AxiosError) => void): void {
    this.load('/v4/booking/portals/' + portalId, callback)
  }

  public loadAdminPortal(callback: (err: AxiosError) => void): void {
    this.load('/v4/site/calendars/any/portals/admin/bootstrap', callback)
  }

  private load(url, callback: (err: AxiosError) => void): void {
    this.axios
      .get(url)
      .then((response) => {
        const data = response.data.data
        this.portal = new Portal()
        this.portal.populate(data.portal)
        this.places = data.places.map((p) => {
          return new Place(p)
        })
        this.services = data.services.map((s) => {
          return new Service(s)
        })
        this.slots = data.slots.map((s) => {
          return new Slot(s)
        })
        this.resources = data.resources.map((s) => {
          return new Resource(s)
        })
        this.locale = new Locale(data.locale)
        this.firstCanCancelTime = DateTime.fromISO(data.firstCanCancelTime, { zone: 'UTC' })
        this.firstCanModifyTime = DateTime.fromISO(data.firstCanModifyTime, { zone: 'UTC' })
        if (callback) {
          callback(null)
        }
      })
      .catch((err) => {
        console.error('Error fetching portal:', err, err.response?.data)
        if (callback) {
          callback(err)
        }
      })
  }

  public ensurePrimaryService(service: Service): void {
    for (let i = 0; i < this.services.length; i++) {
      if (this.services[i].id === service.id) {
        console.log('ensurePrimaryService: found!')
        return
      }
    }
    this.services.push(service)
  }

  // Available services

  public get allPrimaryServices(): Array<Service> {
    const result = []
    for (let i = 0; i < this.services.length; i++) {
      if (this.services[i].type === 'Primary') {
        result.push(this.services[i])
      }
    }
    return result
  }

  public get allAddonServices(): Array<Service> {
    const result = []
    for (let i = 0; i < this.services.length; i++) {
      if (this.services[i].type !== 'Primary') {
        result.push(this.services[i])
      }
    }
    return result
  }

  // Selected

  public getService(selectedService: SelectedService): Service {
    if (selectedService.serviceId) {
      for (let i = 0; i < this.services.length; i++) {
        if (this.services[i].id === selectedService.serviceId) {
          return this.services[i]
        }
      }
    }
    return null
  }

  public getPlace(selectedService: SelectedService): Place {
    if (selectedService.placeId) {
      for (let i = 0; i < this.places.length; i++) {
        if (this.places[i].id === selectedService.placeId) {
          return this.places[i]
        }
      }
    }
    return null
  }

  public getAddonServices(selectedService: SelectedService): Array<Service> {
    const result = []
    for (let i = 0; i < this.services.length; i++) {
      let selected = false
      for (let j = 0; j < selectedService.addonServiceIds.length; j++) {
        if (selectedService.addonServiceIds[j] === this.services[i].id) {
          selected = true
          break
        }
      }
      if (selected) {
        result.push(this.services[i])
      }
    }
    return result
  }
}
